import React from 'react';
import { PricingPlansSection } from './PricingPlansSection';
import { CompetitiveAdvantageSection } from './CompetitiveAdvantageSection';
import { ContactSection } from './ContactSection';

export const MainResponsiveResearch = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-b from-[#131525] via-[#151b2d] to-[#0f172a] py-8 lg:py-16">
      {/* <PricingPlansSection /> */}
      <ContactSection />
      {/* <CompetitiveAdvantageSection /> */}
    </div>
  );
};
