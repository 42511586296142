import React from 'react';
import Footer from 'app/components/Footer';
import { SoftwareSignals } from 'app/components/Landing/SoftwareSignals';
import { AnimationBox } from 'app/components/Landing/AnimationBox';
import { ReversedCard } from 'app/components/Landing/ReversedCard';
import { ResponsiveResearch } from 'app/components/Landing/ResponsiveResearch';
import AppBar from 'app/components/Layout/AppBar';
import { PricingPlansSection } from 'app/components/Landing/PricingPlansSection';
import { ContactSection } from 'app/components/Landing/ContactSection';
import { CompetitiveAdvantageSection } from 'app/components/Landing/CompetitiveAdvantageSection';

function LandingPage() {
  return (
    <div>
      <AppBar />
      <SoftwareSignals />
      <AnimationBox />
      <ReversedCard />
      <ResponsiveResearch />
      <PricingPlansSection />
      {/* <ContactSection /> */}
      <Footer />
    </div>
  );
}

export default LandingPage;
