import React, { useEffect, useRef, useState, useCallback } from 'react';
import { BoxWithImage } from './BoxWithImage';
import { UcoverDasets } from 'assets/images';
import { Code } from 'assets/images';
import { DailyUpdates } from 'assets/images';
import { set } from 'shelljs';
import DatasetModal from './DatasetModal'; // Ensure the correct path
import { CSSTransition } from 'react-transition-group'; // If using transitions

// First add this interface at the top with other interfaces
// ImageGallery.tsx or the relevant file

interface GalleryImage {
  name: string;
  url: string;
  date?: string;
  description?: string;
  category: string; // Removed the optional modifier
  link?: string;    // Added link property
}


interface ModalProps {
  image: GalleryImage | null;
  images: GalleryImage[];
  currentIndex: number;
  onClose: () => void;
  onNext: () => void;
  onPrev: () => void;
}

const ImageModal: React.FC<ModalProps> = ({ 
  image, 
  images, 
  currentIndex, 
  onClose, 
  onNext, 
  onPrev 
}) => {
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
      if (e.key === 'ArrowRight') onNext();
      if (e.key === 'ArrowLeft') onPrev();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onNext, onPrev]);

  if (!image) return null;

  return (
    <div 
      ref={modalRef}
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', backdropFilter: 'blur(10px)' }}
      onClick={onClose}
    >
    <div
      id="features"
      className="flex flex-col gap-y-2 items-center justify-center bg-gradient-to-b from-[#0d1117] via-[#111827] to-[#131525] pt-0 pb-6"
    >
        {/* Navigation Buttons */}
        <button
          onClick={onPrev}
          className="absolute left-4 top-1/2 -translate-y-1/2 text-white/70 hover:text-white transition-all p-2 rounded-full bg-black/30 hover:bg-black/50"
          disabled={currentIndex === 0}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <button
          onClick={onNext}
          className="absolute right-4 top-1/2 -translate-y-1/2 text-white/70 hover:text-white transition-all p-2 rounded-full bg-black/30 hover:bg-black/50"
          disabled={currentIndex === images.length - 1}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>

        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute -top-12 right-0 text-white/70 hover:text-white transition-colors"
        >
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        {/* Loading Spinner */}
        {loading && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-12 h-12 border-4 border-[#775dda] border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}

        {/* Image */}
        <img
          src={image.url}
          alt={image.name}
          className={`max-h-[80vh] max-w-full object-contain rounded-lg shadow-2xl transition-opacity duration-300 ${
            loading ? 'opacity-0' : 'opacity-100'
          }`}
          onLoad={() => setLoading(false)}
          onError={() => {
            setLoading(false);
            setImageError(true);
          }}
        />

        {/* Image Info */}
        <div className="mt-4 text-center">
          <h3 className="text-white text-xl font-bold">{image.name}</h3>
          {image.description && (
            <p className="text-gray-300 mt-2">{image.description}</p>
          )}
          {image.date && (
            <p className="text-gray-400 mt-1">
              {new Date(image.date).toLocaleDateString()}
            </p>
          )}
          <p className="text-gray-400 mt-2">
            {currentIndex + 1} of {images.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export const ImageGallery = () => {
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const imagesPerPage = 12;
  const initialPage = 1;

  // Fisher-Yates Shuffle Algorithm
  const shuffleArray = useCallback((array: GalleryImage[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }, []);

  const getCleanName = useCallback((filename: string) => {
    return filename
      .replace(/\(\d+\)/g, '')
      .replace(/\.png$/, '')
      .replace(/[_-]/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
      .trim();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://api.github.com/repos/sovai-research/sovai-documentation/contents/.gitbook/assets');
        const data = await response.json();
        
        const categorizeFile = (fileName: string) => {
          // Convert filename to lowercase for consistent matching
          const name = fileName.toLowerCase();
          
          // Risk-related categories
          if (name.includes('risk_indicators') || 
              name.includes('bankruptcy_prediction') ||
              name.includes('liquidity') ||
              name.includes('turing_risk')) {
            return 'Risk Management';
          }
          
          // Market Analysis
          if (name.includes('price_breakout') ||
              name.includes('factor_signals') ||
              name.includes('weight_optimization') ||
              name.includes('institutional_trading') ||
              name.includes('clustering_panels') ||
              name.includes('anomaly_detection')) {
            return 'Market Analysis';
          }
        
          // Financial Data
          if (name.includes('financial_ratios') ||
              name.includes('accounting_data') ||
              name.includes('earnings_surprise') ||
              name.includes('sec_10k') ||
              name.includes('sec_edgar')) {
            return 'Financial Data';
          }
        
          // Government & Regulatory
          if (name.includes('government_contracts') ||
              name.includes('government_traffic') ||
              name.includes('congressional_data') ||
              name.includes('lobbying')) {
            return 'Government & Regulatory';
          }
        
          // Alternative Data
          if (name.includes('wikipedia_views') ||
              name.includes('box_office_stats') ||
              name.includes('phrama_clinical_trials') ||
              name.includes('signal_evaluation')) {
            return 'Alternative Data';
          }
        
          // Flow & Sentiment
          if (name.includes('insider_flow') ||
              name.includes('news_sentiment') ||
              name.includes('asset_rotation') ||
              name.includes('institutional_')) {
            return 'Flow & Sentiment';
          }
        
          // Analysis & Processing
          if (name.includes('feature_importance') ||
              name.includes('extract_features') ||
              name.includes('time_segmentation') ||
              name.includes('pairwise_distance') ||
              name.includes('neutralize_features')) {
            return 'Analysis Tools';
          }
        
          // Core Data
          if (name.includes('core_economic') ||
              name.includes('employee_visa')) {
            return 'Core Data';
          }
        
          // Dashboard items - keep as is since it might be used elsewhere
          if (name.includes('dashboard')) {
            return 'Dashboard';
          }
        
          // Default category
          return 'Other';
        };
  
        const pngFiles = data
        .filter((file: any) => 
          file.name.endsWith('.png') && 
          !file.name.toLowerCase().includes('about') // Exclude files with 'about' in the name
        )
          .map((file: any) => ({
            name: getCleanName(file.name),
            url: file.download_url,
            date: file.commit?.date,
            category: categorizeFile(file.name.toLowerCase()),
            lastUpdated: new Date(file.commit?.date || '').toLocaleDateString(),
            isDocumentation: file.name.endsWith('.md')
          }));
  
        // Sort files by date (most recent first)
        const sortedFiles = shuffleArray(pngFiles);
  
        setImages(sortedFiles);
        setHasMore(sortedFiles.length > page * imagesPerPage);
        
      } catch (error) {
        console.error('Error fetching images:', error);
      }
      setLoading(false);
    };
  
    fetchImages();
  }, [page, getCleanName, imagesPerPage]);

  // Navigation functions
  const handleNext = useCallback(() => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setSelectedImage(images[currentIndex + 1]);
    }
  }, [currentIndex, images]);

  const handlePrev = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
      setSelectedImage(images[currentIndex - 1]);
    }
  }, [currentIndex, images]);

  const handleImageClick = (image: GalleryImage, index: number) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = 'auto';
  };

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
    setExpanded(true);
  };

  const handleCollapse = () => {
    setPage(initialPage);
    setExpanded(false);
    document.getElementById('gallery-top')?.scrollIntoView({ behavior: 'smooth' });
  };

  // Filter images based on search and category
  const filteredImages = images
    .filter(image => 
      image.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === 'all' || image.category === selectedCategory)
    )
    .slice(0, page * imagesPerPage);

  // Categories for filter
  const categories = ['all', ...Array.from(new Set(images.map(img => img.category))).filter(Boolean)];

  return (
    <>
    <div className="w-full py-16 px-4 bg-transparent"> {/* Standardized padding */}
      <div className="max-w-6xl mx-auto">
        <h2 id="gallery-top" className="text-4xl font-bold text-white mb-12 text-center font-manrope"> {/* Standardized margin */}
          Intelligent Screens
          </h2>

          {/* Search and Filter */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-6"> {/* Reduced bottom margin */}
            <input
              type="text"
              placeholder="Search images..."
              className="px-4 py-2 bg-[rgba(13,17,23,0.85)] border border-[#1a2232] rounded-lg text-white focus:border-[#775dda] outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="px-4 py-2 bg-[rgba(13,17,23,0.85)] border border-[#1a2232] rounded-lg text-white focus:border-[#775dda] outline-none"
            >
              {categories.map(category => (
                <option key={category} value={category}>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </option>
              ))}
            </select>
          </div>

          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {filteredImages.map((image, index) => (
              <div
                key={image.name} // Use a unique identifier instead of index
                className="group relative overflow-hidden rounded-xl border border-[#1a2232] hover:border-[#775dda] transition-all duration-300 cursor-pointer"
                onClick={() => handleImageClick(image, index)}
              >
                <div className="aspect-w-16 aspect-h-9 h-[200px]">
                  <img
                    src={image.url}
                    alt={image.name}
                    className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-105"
                    loading="lazy"
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
                  <h3 className="text-white text-sm font-semibold truncate">{image.name}</h3>
                  {image.category && (
                    <span className="text-gray-300 text-xs">{image.category}</span>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Button Group */}
          <div className="flex justify-center gap-4 mt-6"> {/* Adjusted top margin */}
            {hasMore && !loading && (
              <button
                onClick={handleLoadMore}
                className="px-6 py-2 bg-[#775dda] text-white rounded-lg hover:bg-[#8b6dea] transition-colors duration-300"
              >
                Load More
              </button>
            )}
            
            {expanded && (
              <button
                onClick={handleCollapse}
                className="px-6 py-2 border border-[#775dda] text-[#775dda] rounded-lg hover:bg-[#775dda] hover:text-white transition-colors duration-300"
              >
                Collapse
              </button>
            )}
          </div>

          {loading && (
            <div className="flex justify-center mt-8">
              <div className="w-8 h-8 border-4 border-[#775dda] border-t-transparent rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <ImageModal 
        image={selectedImage}
        images={images}
        currentIndex={currentIndex}
        onClose={handleCloseModal}
        onNext={handleNext}
        onPrev={handlePrev}
      />
    </>
  );
};

interface Dataset {
  name: string;
  link: string;
}


const datasets = [
  {
    name: "Accounting Data",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/accounting-data"
  },
  {
    name: "Asset Rotation",
    link: "https://docs.sov.ai/realtime-datasets/economic-datasets/asset-rotation"
  },
  {
    name: "AirBnB Data",
    link: "https://docs.sov.ai/realtime-datasets/sectorial-datasets/airbnb-data"
  },
  {
    name: "Bankruptcy Predictions",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/bankruptcy-predictions"
  },
  {
    name: "Box Office Stats",
    link: "https://docs.sov.ai/realtime-datasets/sectorial-datasets/box-office-stats"
  },
  {
    name: "CFPB Complaints",
    link: "https://docs.sov.ai/realtime-datasets/sectorial-datasets/cfpb-complaints"
  },
  {
    name: "Congressional Data",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/congressional-data"
  },
  {
    name: "Core Economic Data",
    link: "https://docs.sov.ai/realtime-datasets/economic-datasets/core-economic-data"
  },
  {
    name: "Earnings Surprise",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/earnings-surprise"
  },
  {
    name: "Employee Visa",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/employee-visa"
  },
  {
    name: "Factor Signals",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/factor-signals"
  },
  {
    name: "Financial Ratios",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/financial-ratios"
  },
  {
    name: "Government Contracts",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/government-contracts"
  },
  {
    name: "Government Traffic",
    link: "https://docs.sov.ai/realtime-datasets/economic-datasets/government-traffic"
  },
  {
    name: "Institutional Trading",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/institutional-trading"
  },
  {
    name: "Insider Flow Prediction",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/insider-flow-prediction"
  },
  {
    name: "Liquidity Data",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/liquidity-data"
  },
  {
    name: "Lobbying Data",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/lobbying-data"
  },
  {
    name: "News Sentiment",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/news-sentiment"
  },
  {
    name: "Pharma Clinical Trials",
    link: "https://docs.sov.ai/realtime-datasets/sectorial-datasets/pharma-clinical-trials"
  },
  {
    name: "Price Breakout",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/price-breakout"
  },
  {
    name: "Risk Indicators",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/risk-indicators"
  },
  {
    name: "SEC Edgar Search",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/sec-edgar-search"
  },
  {
    name: "SEC 10K Filings",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/sec-10k-filings"
  },
  {
    name: "Short Selling",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/short-selling"
  },
  {
    name: "Turing Risk Index",
    link: "https://docs.sov.ai/realtime-datasets/economic-datasets/turing-risk-index"
  },
  {
    name: "Wikipedia Views",
    link: "https://docs.sov.ai/realtime-datasets/equity-datasets/wikipedia-views"
  },
  {
    name: "Request  Data",
    link: "https://docs.sov.ai/realtime-datasets/sectorial-datasets/request-datasets"
  }
];

const DatasetGrid: React.FC = () => {
  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null);

  // Helper function to get category from URL
  const getCategory = (link: string): string => {
    if (link.includes('economic-datasets')) return 'economic';
    if (link.includes('sectorial-datasets')) return 'sectorial';
    return 'equity';
  };

  // Get color based on category
  const getCategoryColor = (category: string): string => {
    switch (category) {
      case 'economic':
        return 'bg-emerald-500'; // Green for economic
      case 'sectorial':
        return 'bg-purple-500';  // Purple for sectorial
      default:
        return 'bg-[#3ab9d9]';   // Default blue for equity
    }
  };

  // Get label based on category
  const getCategoryLabel = (category: string): string => {
    switch (category) {
      case 'economic':
        return 'Economic';
      case 'sectorial':
        return 'Sectorial';
      default:
        return 'Equity';
    }
  };

  // Debugging: Log when a dataset is selected
  const handleDatasetClick = (dataset: Dataset) => {
    console.log('Dataset clicked:', dataset);
    setSelectedDataset(dataset);
  };

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-white mb-12 text-center font-manrope">
          Uncover Datasets
        </h2>
        
        {/* Legend */}
        <div className="flex justify-center gap-6 mb-8">
          {['equity', 'economic', 'sectorial'].map((category) => (
            <div key={category} className="flex items-center gap-2">
              <div className={`w-2 h-2 rounded-full ${getCategoryColor(category)}`} />
              <span className="text-gray-400 text-sm">{getCategoryLabel(category)}</span>
            </div>
          ))}
        </div>

        {/* Dataset Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {datasets.map((dataset, index) => {
            const category = getCategory(dataset.link);
            return (
              <div
                key={index}
                className="group block cursor-pointer"
                onClick={() => handleDatasetClick(dataset)}
              >
                <div 
                  className="bg-[rgba(13,17,23,0.65)] backdrop-blur-sm border border-[#1a2232] rounded-xl p-4 
                            transition-all duration-300 hover:border-[#3ab9d9] hover:bg-[rgba(13,17,23,0.85)]
                            cursor-pointer"
                >
                  <div className="flex items-center space-x-3">
                    <div className={`w-2 h-2 rounded-full ${getCategoryColor(category)} group-hover:animate-pulse`} />
                    <span className="text-gray-200 text-sm font-medium group-hover:text-[#3ab9d9] transition-colors duration-300">
                      {dataset.name}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modal */}
      <DatasetModal 
        dataset={selectedDataset}
        onClose={() => setSelectedDataset(null)}
      />
    </div>
  );
};

export default DatasetGrid;


// No need to modify BoxWithImage.tsx at all!



// First, define the interface for feature data
interface FeatureItem {
  image: string;
  title: string;
  description: string;
  link: string;
}

// Then declare the featureData array with the type
const featureData: FeatureItem[] = [
  {
    image: UcoverDasets,
    title: 'Uncover Datasets',
    description: 'Gain an unbeatable edge by accessing a world of untapped financial data.',
    link: 'https://docs.sov.ai/realtime-datasets/'
  },
  {
    image: Code,
    title: 'Intelligent Screens',
    description: 'Complex financial and economic data are transformed into actionable insights.',
    link: 'https://docs.sov.ai/pattern-recognition'
  },
  {
    image: DailyUpdates,
    title: 'Automate Research',
    description: 'Replace your existing processes with cheaper and more accurate methods.',
    link: 'https://docs.sov.ai/feature-processing/'
  }
];


export const AnimationBox = () => {
  const [showCursor, setShowCursor] = useState(true);
  const [copied, setCopied] = useState(false);
  const [hoveredFeature, setHoveredFeature] = useState<string | null>(null);
  const command = 'pip install sovai';

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(command);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  // Blinking cursor effect
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 530);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <div
      id="features"
      className="flex flex-col gap-y-2 items-center justify-center bg-gradient-to-b from-[#0d1117] via-[#111827] to-[#131525] pt-24 pb-4"
    >

      {/* NEW HEADING ABOVE THE PIP INSTALL */}
      <h3 className="text-2xl lg:text-3xl font-bold text-white">
        Easy to use Python SDK
      </h3>
      {/* END NEW HEADING SECTION */}


      {/* Copy Command Button */}
      <div className="relative group">
        <div 
          onClick={handleCopy}
          className="flex flex-row text-gray-200 justify-center items-center gap-2 mt-3 lg:mt-5 border border-gray-600 p-3 text-base lg:text-lg font-medium bg-[rgba(13,17,23,0.65)] backdrop-blur-sm rounded-lg cursor-pointer hover:border-[#775dda] transition-colors duration-300 min-w-[200px] sm:min-w-[250px]"
        >
          <div>$</div>
          <div className="text-gray-200">pip install</div>
          <span className="text-[#775dda]">sovai</span>
          <span className={`w-[2px] h-[1.2em] bg-[#775dda] transition-opacity duration-150 ${showCursor ? 'opacity-100' : 'opacity-0'}`}></span>
          
          {/* Tooltip */}
          <div className={`absolute -top-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap px-2 py-1 bg-gray-800 text-gray-200 text-sm rounded opacity-0 transition-opacity duration-300 ${copied ? 'opacity-100' : 'group-hover:opacity-100'}`}>
            {copied ? 'Copied!' : 'Click to copy'}
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="features-container flex flex-col sm:flex-row justify-center items-center gap-y-3 sm:gap-y-0 sm:gap-x-4 md:gap-x-6 lg:gap-x-8 w-11/12 lg:w-4/5 mt-3 lg:mt-4">
        {featureData.map((feature: FeatureItem) => (
          <a
            href={feature.link}
            target="_blank"
            rel="noopener noreferrer"
            className="group no-underline hover:no-underline transition-transform duration-300 transform hover:scale-105 hover:shadow-lg border border-transparent bg-[rgba(13,17,23,0.65)] rounded-lg p-4 cursor-pointer"
            onMouseEnter={() => setHoveredFeature(feature.title)}
            // onMouseLeave={() => setHoveredFeature(null)} // Optional: Handle onMouseLeave if needed
          >
            <BoxWithImage
              props={{
                image: feature.image,
                title: feature.title,
                description: feature.description,
              }}
            />
          </a>
        ))}
      </div>


      {/* Conditionally Rendered Content Based on Hover */}
      <div className="w-full mt-6">
        {/* Optional: Use CSSTransition for smooth animations */}
        {/* <CSSTransition
          in={(!hoveredFeature || hoveredFeature === 'Uncover Datasets' ) && <DatasetGrid />}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <DatasetGrid />
        </CSSTransition>
        <CSSTransition
          in={hoveredFeature === 'Intelligent Screens'}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <ImageGallery />
        </CSSTransition> */}

        {/* Without Transitions */}
        {(!hoveredFeature || hoveredFeature === 'Uncover Datasets' ) && <DatasetGrid />}

        {/* {hoveredFeature === 'Uncover Datasets' && <DatasetGrid />} */}
        {hoveredFeature === 'Intelligent Screens' && <ImageGallery />}
      </div>
    </div>
  );
};
