import React, { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import emailjs from '@emailjs/browser';

export const ContactSection = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageLength, setMessageLength] = useState(0);
  const maxMessageLength = 500;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await emailjs.sendForm(
        'service_p2gn1hf', // Replace with your actual Service ID
        'template_h6cmv5d', // Replace with your actual Template ID
        e.currentTarget,
        'TCryOR4_bl0OJJ8-g' // Replace with your actual Public Key
      );

      toast.success('Message sent successfully!');
      (e.target as HTMLFormElement).reset();
      setMessageLength(0);
    } catch (error) {
      toast.error('Failed to send message. Please try again.');
      console.error('EmailJS Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageLength(e.target.value.length);
  };

  // Base input/select/textarea classes for consistency
  const inputBaseClasses = "block w-full rounded-lg bg-[#1a2340] border border-blue-900/50 shadow-sm focus:border-blue-500 focus:ring-blue-500 hover:border-blue-700 transition duration-150 ease-in-out text-white placeholder-gray-400/60 px-4 py-2.5 text-sm"; // Added rounded-lg, adjusted padding/text size slightly, adjusted border opacity

  return (
    // Adjusted overall width slightly if needed, kept responsive widths
    <div id="contact" className="w-11/12 lg:w-10/12 xl:w-9/12 mx-auto mt-20 mb-16"> {/* Adjusted margins, slightly wider on larger screens */}
      <h3 className="text-3xl text-white font-bold mb-8 text-center">Contact Us</h3> {/* Increased font size and margin-bottom */}

      {/* Increased max-width for the card itself */}
      <div className="max-w-4xl mx-auto bg-gradient-to-b from-[#121d35] to-[#0e1628] rounded-2xl overflow-hidden border border-blue-900/40 shadow-2xl p-10 md:p-12"> {/* Increased max-width, padding, border-radius, shadow, border opacity */}
        
        {/* Enhanced the header section */}
        <div className="text-center mb-10"> {/* Increased margin-bottom */}
          <div className="mx-auto w-20 h-20 mb-5 bg-blue-600/10 rounded-full flex items-center justify-center border border-blue-500/20"> {/* Increased size, changed bg opacity, added subtle border */}
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"> {/* Increased icon size */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </div>
          <h2 className="text-2xl font-semibold text-white mb-3"> {/* Increased font size, adjusted weight */}
            Get in Touch
          </h2>
          <p className="text-gray-300/90 mb-4 max-w-md mx-auto"> {/* Adjusted text opacity, added max-width for readability */}
            Schedule a personalized demo or send us your questions.
          </p>
          <div className="flex justify-center space-x-4 text-gray-400 text-base"> {/* Increased text size */}
            <span>📞 +1 (617) 999-2099</span> {/* Example number */}
          </div>
        </div>

        {/* Increased spacing within the form */}
        <form onSubmit={handleSubmit} className="space-y-6"> {/* Increased space-y */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6"> {/* Increased gap */}
            <div>
              <label htmlFor="from_name" className="block text-sm font-medium text-gray-300 mb-1.5"> {/* Adjusted margin-bottom */}
                👤 Name <span className="text-red-400/80 text-base">*</span> {/* Adjusted required star */}
              </label>
              <input
                type="text"
                id="from_name"
                name="from_name"
                required
                placeholder="Your full name"
                className={inputBaseClasses}
              />
            </div>
            <div>
              <label htmlFor="reply_to" className="block text-sm font-medium text-gray-300 mb-1.5">
                ✉️ Email <span className="text-red-400/80 text-base">*</span>
              </label>
              <input
                type="email"
                id="reply_to"
                name="reply_to"
                required
                placeholder="your.email@example.com"
                className={inputBaseClasses}
              />
            </div>
            <div>
              <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1.5">
                🏢 Company
              </label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="Your company name"
                className={inputBaseClasses}
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-1.5">
                📞 Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="(Optional) Your phone number"
                className={inputBaseClasses}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6"> {/* Increased gap */}
            <div>
              <label htmlFor="reason" className="block text-sm font-medium text-gray-300 mb-1.5">
                🎯 Reason for Contact
              </label>
              <select
                id="reason"
                name="reason"
                className={inputBaseClasses}
              >
                <option value="">Select a reason</option>
                <option value="Demo Request">Demo Request</option>
                <option value="Product Information">Product Information</option>
                <option value="Pricing">Pricing</option>
                <option value="Technical Support">Technical Support</option>
                <option value="Partnership">Partnership Inquiry</option> {/* Added another option */}
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label htmlFor="preferred_time" className="block text-sm font-medium text-gray-300 mb-1.5">
                🕒 Preferred Demo Time (Optional)
              </label>
              <select
                id="preferred_time"
                name="preferred_time"
                className={inputBaseClasses}
              >
                <option value="">Select a preferred time slot</option>
                <option value="Morning (9am-12pm EST)">Morning (9am-12pm EST)</option>
                <option value="Afternoon (1pm-5pm EST)">Afternoon (1pm-5pm EST)</option>
                <option value="Flexible">Flexible</option> {/* Added Flexible option */}
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1.5 flex justify-between items-end">
              <span>💬 Message</span>
              <span className="text-xs text-gray-400 font-medium">
                {messageLength}/{maxMessageLength}
              </span>
            </label>
            <textarea
              id="message"
              name="message"
              rows={5} // Increased rows slightly
              maxLength={maxMessageLength}
              onChange={handleMessageChange}
              className={`${inputBaseClasses} resize-y`} // Added resize-y
              placeholder="Tell us how we can help you..."
            />
            {/* Moved character count to be part of the label row */}
          </div>

          {/* Slightly refined privacy section */}
          <div>
            <div className="flex items-start p-4 bg-[#0c1526]/80 rounded-lg border border-blue-900/40"> {/* Adjusted padding, bg opacity, border-radius, border opacity */}
              <div className="flex items-center h-5 mt-0.5"> {/* Aligned checkbox better */}
                <input
                  id="privacy"
                  name="privacy"
                  type="checkbox"
                  required
                  className="h-5 w-5 text-blue-500 border-gray-500 rounded focus:ring-blue-500 bg-transparent focus:ring-offset-0 focus:ring-2" // Styled checkbox
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="privacy" className="text-gray-300 font-medium">
                  I agree to the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Privacy Policy</a> <span className="text-red-400/80 text-base">*</span> {/* Made privacy policy a link */}
                </label>
                <p className="text-xs text-gray-400/80 mt-1"> {/* Adjusted text opacity */}
                  Your data is secured and will only be used to respond to your inquiry.
                </p>
              </div>
            </div>
          </div>

          {/* Slightly bolder button */}
          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-lg text-base font-semibold text-white bg-gradient-to-r from-purple-600 to-blue-500 hover:from-purple-700 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 focus:ring-offset-[#121d35] disabled:opacity-60 disabled:cursor-not-allowed transition-all duration-150 ease-in-out transform hover:scale-[1.01]" // Added bolder font, shadow-lg, focus ring offset, disabled opacity, hover transform
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </>
              ) : (
                'Request Demo / Send Message' // Updated button text slightly
              )}
            </button>
          </div>

          <div className="text-center text-xs text-gray-400/80 mt-6 space-y-1.5"> {/* Adjusted margin-top, text opacity, spacing */}
            <p>By submitting, you agree to our Privacy Policy and Terms of Service.</p>
            <p>We aim to respond within 1-2 business days.</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactSection;
