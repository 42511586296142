import React from 'react';

interface DatasetItemProps {
  name: string;
}

const DatasetItem: React.FC<DatasetItemProps> = ({ name }) => {
  return (
    <div className="py-1.5 px-2 bg-[#1d283830] border border-[#1d2838] rounded-md m-1 text-sm text-white hover:bg-[#1d283850] hover:border-[#3d4e6a] transition-colors">
      {name}
    </div>
  );
};

export const DatasetList: React.FC = () => {
  const datasets = [
    'Accounting Data',
    'Asset Rotation',
    'AirBnB Data',
    'Bankruptcy Predictions',
    'Box Office Stats',
    'CFPB Complaints',
    'Congressional Data',
    'Core Economic Data',
    'Earnings Surprise',
    'Employee Visa',
    'Factor Signals',
    'Financial Ratios',
    'Government Contracts',
    'Government Traffic',
    'Institutional Trading',
    'Insider Flow Prediction',
    'Liquidity Data',
    'Lobbying Data',
    'News Sentiment',
    'Pharma Clinical Trials',
    'Price Breakout',
    'Risk Indicators',
    'SEC Edgar Search',
    'SEC 10K Filings',
    'Short Selling',
    'Turing Risk Index',
    'Wikipedia Views',
    'Request Data',
  ];

  const datasetCount = datasets.length;
  const pricePerDataset = (285 / datasetCount).toFixed(2);

  return (
    <div className="flex flex-col sm:w-[380px] lg:w-[450px] border border-[#222e45] rounded-xl p-6 bg-[#131a2b80] transition-all duration-300 ease-in-out hover:border-[#3d4e6a] hover:shadow-[0_0_20px_rgba(89,112,158,0.3)]">
      <h3 className="text-white text-xl lg:text-2xl font-bold mb-4 text-center">
        All {datasetCount} Premium Datasets Included
      </h3>
      
      <div className="bg-gradient-to-r from-green-600/20 to-blue-600/20 p-3 rounded-lg border border-green-500/30 mb-4 text-center">
        <span className="text-white text-sm">Just </span>
        <span className="text-green-400 font-bold text-xl">${pricePerDataset}</span>
        <span className="text-white text-sm"> per dataset</span>
      </div>
      
      <div className="flex flex-wrap justify-center mb-4 max-h-[360px] overflow-y-auto custom-scrollbar">
        {datasets.map((dataset, index) => (
          <DatasetItem key={index} name={dataset} />
        ))}
      </div>
      
      <div className="mt-auto text-center text-gray-400 text-xs">
        Request additional datasets at no extra cost
      </div>
    </div>
  );
};
