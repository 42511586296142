import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Dataset, 
  Connection, 
  PredictionPath, 
  DataFlowParticle,
  DatasetGroup,
  DataNexusVisualizationProps,
  getCategoryColor,
  getConfidenceClass,
  getPredictedMoveClass,
  getDefaultDatasets,
  getDefaultPredictionPaths,
  getFinancialPaths,
  getGovernmentPaths,
  getRiskPaths,
  getEconomicPaths,
  adjustPath,
  getCurrentTime,
  getCurrentDate,
  datasetGroups,
  getRandomDatasetGroup,
  getNextDatasetGroup
} from './DataNexusUtils';
import {
  Grid,
  ConnectionLines,
  DatasetNodes,
  PredictionGraph,
  InfoOverlay,
  TimestampBar,
  CategoryLegend,
  AnalysisIndicators
} from './DataNexusComponents';

const DataNexusVisualization: React.FC<DataNexusVisualizationProps> = ({ className = '' }) => {
  // Refs for animation
  const animationRef = useRef<number | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  
  // State for animation and interaction
  const [animationStarted, setAnimationStarted] = useState(false);
  const [hoverNode, setHoverNode] = useState<string | null>(null);
  const [confidenceLevel, setConfidenceLevel] = useState("76%");
  const [predictedMove, setPredictedMove] = useState("+3.2%");
  const [keyFactors, setKeyFactors] = useState("Gov Contracts, SEC Filings");
  const [paths, setPaths] = useState<PredictionPath>(getDefaultPredictionPaths());
  
  // State for datasets and connections
  const [datasets, setDatasets] = useState<Dataset[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [hoveredDataset, setHoveredDataset] = useState<Dataset | null>(null);
  const [flowParticles, setFlowParticles] = useState<DataFlowParticle[]>([]);
  const [currentDatasetGroupId, setCurrentDatasetGroupId] = useState('primary');
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Current date and time for display
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  // Function to load a dataset group and create connections
  const loadDatasetGroup = useCallback((datasetGroup: DatasetGroup) => {
    setIsTransitioning(true);
    
    // Clear any hover state
    setHoverNode(null);
    setHoveredDataset(null);
    
    // Reset paths
    setPaths(getDefaultPredictionPaths());
    
    // Get new datasets
    const datasetsList = datasetGroup.datasets;
    
    // Generate connections based on the dataset relationships
    const connectionsList: Connection[] = [];
    
    datasetsList.forEach(dataset => {
      dataset.connections.forEach(targetId => {
        connectionsList.push({
          source: dataset.id,
          target: targetId,
          strength: Math.random() * 0.5 + 0.5,
        });
      });
    });
    
    // Update state
    setDatasets(datasetsList);
    setConnections(connectionsList);
    setCurrentDatasetGroupId(datasetGroup.id);
    
    // Reset flow particles
    setFlowParticles([]);
    
    // End transition after a delay
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  }, []);

  // Initialize datasets 
  useEffect(() => {
    // Load the initial dataset group
    const initialGroup = datasetGroups.find(g => g.id === 'primary') || datasetGroups[0];
    loadDatasetGroup(initialGroup);
    
    // Start animation after a delay
    const timer = setTimeout(() => {
      setAnimationStarted(true);
    }, 500);
    
    // Update time every minute
    const timeInterval = setInterval(() => {
      setCurrentTime(getCurrentTime());
      setCurrentDate(getCurrentDate());
    }, 60000);
    
    return () => {
      clearTimeout(timer);
      clearInterval(timeInterval);
    };
  }, [loadDatasetGroup]);
  
  // Separate effect for dataset rotation to avoid dependency issues
  useEffect(() => {
    // Rotate datasets periodically - shorter interval for demo purposes
    const rotationInterval = setInterval(() => {
      // Don't rotate if hovering over a node
      if (hoverNode) return;
      
      // Explicitly get the next dataset group
      const nextGroup = getNextDatasetGroup(currentDatasetGroupId);
      loadDatasetGroup(nextGroup);
    }, 15000); // Rotate every 15 seconds for more obvious rotation
    
    return () => {
      clearInterval(rotationInterval);
    };
  }, [loadDatasetGroup, currentDatasetGroupId, hoverNode]);

  // Animation effect for nodes pulsing and connections flowing
  useEffect(() => {
    if (!animationStarted || isTransitioning) return;
    
    // Create initial flow particles - fewer particles for more subtle effect
    const initialParticles = connections
      .filter((_, i) => i % 2 === 0) // Only use half the connections for particles
      .map((connection, index) => {
        const source = datasets.find(d => d.id === connection.source);
        const target = datasets.find(d => d.id === connection.target);
        
        if (!source || !target) return null;
        
        return {
          id: `particle-${index}`,
          x: source.x,
          y: source.y,
          sourceId: connection.source,
          targetId: connection.target,
          progress: Math.random(), // Random starting position
        };
      }).filter(Boolean) as DataFlowParticle[];
    
    setFlowParticles(initialParticles);
    
    let lastTimestamp = 0;
    const animate = (timestamp: number) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const elapsed = timestamp - lastTimestamp;
      
      // Update flow particles
      setFlowParticles(prevParticles => 
        prevParticles.map(particle => {
          const source = datasets.find(d => d.id === particle.sourceId);
          const target = datasets.find(d => d.id === particle.targetId);
          
          if (!source || !target) return particle;
          
          // Update progress
          let newProgress = particle.progress + (elapsed / 10000); // Even slower for smoother animation
          if (newProgress > 1) newProgress = 0;
          
          // Calculate new position along the path
          const newX = source.x + (target.x - source.x) * newProgress;
          const newY = source.y + (target.y - source.y) * newProgress;
          
          return {
            ...particle,
            x: newX,
            y: newY,
            progress: newProgress,
          };
        })
      );
      
      // Occasionally update prediction paths to simulate market movement
      if (Math.random() < 0.001) { // Even lower chance for more subtle animation
        // Subtle random variations in the prediction paths
        const variationAmount = 0.3; // Less variation amount
        const randomShift = (Math.random() - 0.5) * variationAmount;
        
        setPaths(prevPaths => {
          return {
            main: adjustPath(prevPaths.main, randomShift),
            upper: adjustPath(prevPaths.upper, randomShift),
            lower: adjustPath(prevPaths.lower, randomShift),
          };
        });
      }
      
      lastTimestamp = timestamp;
      animationRef.current = requestAnimationFrame(animate);
    };
    
    animationRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [animationStarted, connections, datasets, isTransitioning]);

  // Handle node hover
  const handleNodeHover = (id: string) => {
    setHoverNode(id);
    const dataset = datasets.find(d => d.id === id);
    setHoveredDataset(dataset || null);
    
    // Update predictive cone based on hovered dataset
    if (dataset) {
      // Different prediction paths for different node types
      if (dataset.category === 'financial') {
        setPaths(getFinancialPaths());
        setConfidenceLevel("82%");
        setPredictedMove("+4.1%");
        setKeyFactors("Financial Ratios, SEC Filings");
      } else if (dataset.category === 'government') {
        setPaths(getGovernmentPaths());
        setConfidenceLevel("76%");
        setPredictedMove("-1.8%");
        setKeyFactors("Gov Contracts, Lobbying Data");
      } else if (dataset.category === 'risk') {
        setPaths(getRiskPaths());
        setConfidenceLevel("89%");
        setPredictedMove("-5.3%");
        setKeyFactors("Bankruptcy Risk, Liquidity Data");
      } else {
        setPaths(getEconomicPaths());
        setConfidenceLevel("71%");
        setPredictedMove("+2.7%");
        setKeyFactors("Economic Data, Asset Rotation");
      }
    }
  };

  // Handle node leave
  const handleNodeLeave = () => {
    setHoverNode(null);
    setHoveredDataset(null);
    
    // Reset to default paths
    setPaths(getDefaultPredictionPaths());
    setConfidenceLevel("76%");
    setPredictedMove("+3.2%");
    setKeyFactors("Gov Contracts, SEC Filings");
  };

  // Get class for confidence level
  const confidenceClass = getConfidenceClass(confidenceLevel);

  // Get class for predicted move
  const predictedMoveClass = getPredictedMoveClass(predictedMove);

  return (
    <div className={`relative w-full h-full ${className}`}>
      {/* Main SVG for the visualization */}
      <svg 
        ref={svgRef}
        viewBox="0 0 240 100" 
        preserveAspectRatio="xMidYMid meet"
        className="w-full h-full" 
        style={{ backgroundColor: '#0d1117', borderRadius: '8px' }}
      >
        {/* Grid lines */}
        <Grid />
        
        {/* Connection lines */}
        <ConnectionLines 
          connections={connections} 
          datasets={datasets} 
          flowParticles={flowParticles} 
          hoverNode={hoverNode}
          animationStarted={animationStarted}
        />
        
        {/* Dataset Nodes */}
        <DatasetNodes 
          datasets={datasets} 
          handleNodeHover={handleNodeHover} 
          handleNodeLeave={handleNodeLeave} 
          hoverNode={hoverNode}
        />
        
        {/* Prediction graph */}
        <PredictionGraph 
          paths={paths} 
          animationStarted={animationStarted} 
        />
      </svg>
      
      {/* Information Overlay */}
      <InfoOverlay 
        hoveredDataset={hoveredDataset} 
        getCategoryColor={getCategoryColor} 
      />
      
      {/* Timestamp and Instructions */}
      <TimestampBar 
        date={currentDate} 
        time={currentTime} 
      />
      
      {/* Category Legend */}
      <CategoryLegend 
        getCategoryColor={getCategoryColor} 
      />
      
      {/* Analysis Indicators */}
      <AnalysisIndicators 
        confidenceLevel={confidenceLevel}
        confidenceClass={confidenceClass}
        predictedMove={predictedMove}
        predictedMoveClass={predictedMoveClass}
        keyFactors={keyFactors}
      />
    </div>
  );
};

export default DataNexusVisualization;
