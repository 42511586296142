import React from 'react';
import { PlanBox } from './PlanBox';
import { DatasetList } from './DatasetList';
import { PlanProps } from 'app/components/types/ResponsiveResearch';

// Pricing plan data
const planData: PlanProps[] = [
  {
    title: 'Professional Plan',
    description: '',
    price: '285',
    features: [
      {
        feature: 'Unlimited queries per month',
        available: true,
      },
      {
        feature: 'Access to all datasets',
        available: true,
      },
      {
        feature: "1 user (that's you!)",
        available: true,
      },
      {
        feature: 'Commercial use',
        available: true,
      },
      {
        feature: 'Dedicated support',
        available: false,
      },
    ],
  },
  {
    title: 'Corporate Plan',
    description: '',
    price: '1395',
    features: [
      {
        feature: 'Unlimited queries per month',
        available: true,
      },
      {
        feature: 'Access to all datasets',
        available: true,
      },
      {
        feature: '5 users (different IPs)',
        available: true,
      },
      {
        feature: 'Commercial use',
        available: true,
      },
      {
        feature: 'Dedicated support',
        available: true,
      },
    ],
  },
];

export const PricingPlansSection = () => {
  return (
    <div 
      id="pricing"
      className="w-full flex flex-col items-center justify-center gap-y-8 mt-28 pb-32"
    >
      {/* Value proposition messaging */}
      <div className="w-11/12 lg:w-4/5 text-center mb-6">
        <h2 className="text-3xl lg:text-4xl text-white font-bold mb-4">
          Government Data for Serious Investors
        </h2>
        <p className="text-xl text-gray-300 mb-6">
          90% cheaper than competitors with 10x more data coverage
        </p>
      </div>

      <div className="flex flex-col lg:flex-row justify-center items-center gap-y-8 lg:gap-y-0 lg:gap-x-6 w-11/12 lg:w-[95%]">
        {planData.map((plan, index) => (
          <PlanBox props={plan} key={index} />
        ))}
        <DatasetList />
      </div>
    </div>
  );
};
