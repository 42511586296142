import React, { useState } from 'react';
import { PlanProps } from 'app/components/types/ResponsiveResearch';
import { Fail, Done } from 'assets/images';
import { Button } from 'app/components/Button';
import { useNavigate } from 'react-router-dom';

export const PlanBox = ({ props }: { props: PlanProps }) => {
  const navigate = useNavigate();
  const [priceColor, setPriceColor] = useState('#989a9f');
  const isPopularPlan = props.title === 'Corporate Plan';

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const handlePriceHover = () => {
    // Using a consistent green color to indicate savings
    setPriceColor('#22c55e');
  };

  const handlePriceLeave = () => {
    setPriceColor('#989a9f'); // Reset to original color
  };

  return (
    <div className={`gap-y-4 lg:gap-y-8 sm:w-[350px] sm:h-[450px] lg:h-[580px] lg:w-[400px] 
      border ${isPopularPlan ? 'border-purple-500' : 'border-[#222e45]'} rounded-xl flex flex-col items-start justify-center p-8 lg:px-10 
      lg:py-8 ${isPopularPlan ? 'bg-[#151f3880]' : 'bg-[#131a2b80]'} transition-all duration-300 ease-in-out relative
      hover:border-[#3d4e6a] hover:shadow-[0_0_20px_rgba(89,112,158,0.3)]`}>
      
      {/* Popular badge for Corporate Plan */}
      {isPopularPlan && (
        <div className="absolute -top-4 right-4 bg-gradient-to-r from-purple-600 to-blue-600 text-white text-sm font-bold py-1 px-3 rounded-full">
          Most Popular
        </div>
      )}
      
      <div className="text-white text-xl lg:text-2xl xl:text-3xl font-bold border-b-2 border-[#192331] w-full pb-4">
        {props.title}
      </div>
      
      {/* Value indicator */}
      {props.title === 'Professional Plan' && (
        <div className="bg-green-800/20 text-green-400 text-xs font-bold py-1 px-2 rounded-md mt-2">
          90% CHEAPER THAN COMPETITORS
        </div>
      )}
      
      <div 
        className="font-bold text-2xl lg:text-3xl xl:text-4xl mb-2 transition-colors duration-300"
        style={{ color: priceColor }}
        onMouseEnter={handlePriceHover}
        onMouseLeave={handlePriceLeave}
      >
        {USDollar.format(Number(props.price))}
        <span className="text-base">/month</span>
      </div>
      
      {/* Competitor comparison for professional plan */}
      {props.title === 'Professional Plan' && (
        <div className="text-red-400 text-sm mb-4">
          <span className="line-through">$2,850+</span> elsewhere
        </div>
      )}
      
      <div>
        <div className="flex flex-col items-start justify-start gap-y-6 text-white">
          {props.features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-start gap-x-2 md:gap-x-3"
            >
              <div
                className={`${feature.available ? 'w-3 h-3' : 'w-2 h-2 -mt-1'}`}
              >
                <img
                  src={feature.available ? Done : Fail}
                  alt="w-full h-full"
                />
              </div>
              <div
                className={`font-medium text-base lg:text-lg xl:text-lg ${
                  feature.available ? 'text-[#d1d1d1]' : 'text-[#7a7f8e]'
                }`}
              >
                {feature.feature}
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Free trial notice */}
      <div className="text-gray-400 text-xs mt-4 mb-2 text-center w-full">
        30-day money-back guarantee
      </div>
      
      <div className="w-full flex flex-row items-center justify-center mt-2">
        <Button
          size="small"
          className={`${isPopularPlan ? 'bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700' : ''}`}
          onClick={() => {
            navigate('/home');
          }}
        >
          {isPopularPlan ? 'Get Corporate Access' : 'Start Professional Plan'}
        </Button>
      </div>
    </div>
  );
};
