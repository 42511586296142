import React from 'react';
import {
  Dataset,
  Connection,
  DataFlowParticle,
  PredictionPath,
  getCategoryColor,
  getCategoryOpacity,
} from './DataNexusUtils';

// Grid component
export const Grid: React.FC = () => (
  <g className="grid-lines">
    {Array.from({ length: 11 }).map((_, i) => (
      <line 
        key={`h-line-${i}`}
        x1="0" 
        y1={i * 10} 
        x2="240" 
        y2={i * 10} 
        stroke="#ffffff" 
        strokeWidth="0.08" 
        strokeOpacity="0.12" 
      />
    ))}
    {Array.from({ length: 25 }).map((_, i) => (
      <line 
        key={`v-line-${i}`}
        x1={i * 10} 
        y1="0" 
        x2={i * 10} 
        y2="100" 
        stroke="#ffffff" 
        strokeWidth="0.08" 
        strokeOpacity="0.12" 
      />
    ))}
  </g>
);

// Connection Lines component
interface ConnectionLinesProps {
  connections: Connection[];
  datasets: Dataset[];
  flowParticles: DataFlowParticle[];
  hoverNode: string | null;
  animationStarted: boolean;
}

export const ConnectionLines: React.FC<ConnectionLinesProps> = ({ 
  connections, 
  datasets, 
  flowParticles, 
  hoverNode,
  animationStarted 
}) => (
  <g className="connections">
    {connections.map((connection, i) => {
      const source = datasets.find(d => d.id === connection.source);
      const target = datasets.find(d => d.id === connection.target);
      if (!source || !target) return null;
      
      // Create gradient for the connection line
      const gradientId = `connection-gradient-${i}`;
      const isHighlighted = hoverNode && (connection.source === hoverNode || connection.target === hoverNode);
      const strokeWidth = isHighlighted ? 0.5 : 0.2;
      
      return (
        <g key={`connection-${i}`}>
          <defs>
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor={getCategoryColor(source.category)} stopOpacity="0.5" />
              <stop offset="100%" stopColor={getCategoryColor(target.category)} stopOpacity="0.5" />
            </linearGradient>
          </defs>
          
          <line 
            x1={source.x} 
            y1={source.y} 
            x2={target.x} 
            y2={target.y} 
            stroke={`url(#${gradientId})`} 
            strokeWidth={strokeWidth}
            strokeOpacity={isHighlighted ? 0.6 : 0.25}
            className="transition-all duration-300"
          />
          
          {/* Animated data flow particles */}
          {flowParticles
            .filter(particle => 
              (particle.sourceId === connection.source && particle.targetId === connection.target) &&
              (hoverNode ? connection.source === hoverNode || connection.target === hoverNode : true)
            )
            .map(particle => (
              <circle 
                key={particle.id}
                cx={particle.x} 
                cy={particle.y}
                r={isHighlighted ? 0.6 : 0.4} 
                fill={getCategoryColor(source.category)}
                opacity={isHighlighted ? 0.8 : 0.5}
                className="drop-shadow-sm"
              />
            ))
          }
        </g>
      );
    })}
  </g>
);

// Dataset Nodes component
interface DatasetNodesProps {
  datasets: Dataset[];
  handleNodeHover: (id: string) => void;
  handleNodeLeave: () => void;
  hoverNode: string | null;
}

export const DatasetNodes: React.FC<DatasetNodesProps> = ({ 
  datasets, 
  handleNodeHover, 
  handleNodeLeave, 
  hoverNode 
}) => (
  <g className="nodes">
    {datasets.map((dataset) => {
      const isHovered = hoverNode === dataset.id;
      const fillColor = getCategoryColor(dataset.category);
      const fillOpacity = getCategoryOpacity(dataset.category, isHovered);
      const strokeOpacity = isHovered ? 0.9 : 0.7;
      const radius = isHovered ? dataset.radius * 1.2 : dataset.radius;
      const shouldShowLabel = isHovered || dataset.value > 85 || ['market1', 'gov1', 'risk3', 'econ1'].includes(dataset.id);
      
      return (
        <g 
          key={dataset.id}
          onMouseEnter={() => handleNodeHover(dataset.id)}
          onMouseLeave={handleNodeLeave}
          className="cursor-pointer"
        >
          {/* Very subtle pulsing halo effect for hovered node */}
          {isHovered && (
            <circle 
              cx={dataset.x} 
              cy={dataset.y} 
              r={radius * 1.25} 
              fill={fillColor} 
              opacity="0.12" 
              className="animate-pulse"
            />
          )}
          
          <circle 
            cx={dataset.x} 
            cy={dataset.y} 
            r={radius * 1.15} // Slightly larger hit area for better interaction
            fill={fillColor} 
            fillOpacity={fillOpacity}
            stroke={fillColor} 
            strokeWidth={isHovered ? 0.4 : 0.15}
            strokeOpacity={strokeOpacity}
            className="transition-all duration-500"
          />
          
          {/* Value indicator inside node (like a pie chart) */}
          <circle 
            cx={dataset.x} 
            cy={dataset.y} 
            r={radius * 0.6} 
            fill={fillColor}
            fillOpacity={dataset.value / 100 * 0.9 + 0.1}
            className="transition-all duration-300"
          />
          
          {/* Node labels - only show for hovered or specific important nodes */}
          {shouldShowLabel && (
            <g className="pointer-events-none">
              {/* Label background */}
              <rect
                x={dataset.x + radius + 1}
                y={dataset.y - 3}
                width={dataset.name.length * 1.7 + 4}
                height={6}
                rx={1.5}
                fill="rgba(13,17,23,0.75)"
                stroke={isHovered ? fillColor : 'rgba(100,100,100,0.15)'}
                strokeWidth={0.1}
                className={`transition-opacity duration-300 ${isHovered ? 'opacity-85' : 'opacity-65'}`}
              />
              {/* Label text */}
              <text
                x={dataset.x + radius + 3}
                y={dataset.y + 1.5}
                fontSize={isHovered ? 3 : 2.5}
                fill={isHovered ? "#ffffff" : "#dddddd"}
                className="transition-all duration-300"
              >
                {dataset.name}
              </text>
            </g>
          )}
        </g>
      );
    })}
  </g>
);

// Prediction Graph component
interface PredictionGraphProps {
  paths: PredictionPath;
  animationStarted: boolean;
}

export const PredictionGraph: React.FC<PredictionGraphProps> = ({ paths, animationStarted }) => (
  <g className="prediction-graph" transform="translate(0, 40)">
    {/* Historical Data Line - Extended to about 2/3 of the chart (x=160) with more realistic stock pattern */}
    <path 
      d="M0,25 L5,24.5 L10,24.7 L15,25.2 L20,24.8 L25,24.2 L30,23.6 L35,23.8 L40,22.9 L45,22.7 L50,21.3 
         L55,20.8 L60,21.2 L65,20.6 L70,19.3 L75,18.7 L80,19.1 L85,18.4 L90,17.6 L95,17.9 
         L100,16.5 L105,16.8 L110,15.7 L115,15.3 L120,15.8 L125,14.9 L130,14.1 L135,13.7 
         L140,12.9 L145,12.3 L150,11.8 L155,11.2 L160,10.3" 
      fill="none" 
      stroke="#4285F4" 
      strokeWidth="0.4"
      className="drop-shadow-sm"
    />
    
    {/* Current Point - Moved to x=160 */}
    <circle cx="160" cy="10.3" r="1" fill="#4285F4" className="animate-pulse" />
    
    {/* Modified prediction paths to start at x=160 and go to x=240 */}
    
    {/* Predicted Range - Lower Bound */}
    <path 
      d={paths.lower}
      fill="none" 
      stroke="#818cf8" 
      strokeWidth="0.4"
      strokeDasharray="0.8,0.8"
      className={`opacity-60 transition-all duration-300 ${animationStarted ? 'opacity-60' : 'opacity-0'}`}
    />
    
    {/* Predicted Range - Main Prediction */}
    <path 
      d={paths.main}
      fill="none" 
      stroke="#818cf8" 
      strokeWidth="0.6"
      strokeDasharray="1.2,0.4"
      className={`transition-all duration-300 ${animationStarted ? 'opacity-100' : 'opacity-0'}`}
    />
    
    {/* Predicted Range - Upper Bound */}
    <path 
      d={paths.upper}
      fill="none" 
      stroke="#818cf8" 
      strokeWidth="0.4"
      strokeDasharray="0.8,0.8"
      className={`opacity-60 transition-all duration-300 ${animationStarted ? 'opacity-60' : 'opacity-0'}`}
    />
    
    {/* Dynamic Prediction Cone - Simplified to use our pre-formatted paths directly */}
    <path 
      d={`M160,10.3 ${paths.upper.substring(paths.upper.indexOf(' ') + 1)} L240,0 L240,24 ${paths.lower.substring(paths.lower.indexOf(' ') + 1).split(' ').reverse().join(' ')}`}
      fill="url(#blueGradient)" 
      opacity="0.08"
      className={`transition-all duration-300 ${animationStarted ? 'opacity-8' : 'opacity-0'}`}
    />
    
    {/* Define gradient */}
    <defs>
      <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#4285F4" stopOpacity="0.6" />
        <stop offset="100%" stopColor="#818cf8" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </g>
);

// Information Overlay component
interface InfoOverlayProps {
  hoveredDataset: Dataset | null;
  getCategoryColor: (category: string) => string;
}

export const InfoOverlay: React.FC<InfoOverlayProps> = ({ hoveredDataset, getCategoryColor }) => (
  <div className="absolute top-2 right-2 p-2 bg-[rgba(13,17,23,0.6)] rounded-lg border border-gray-800 text-xs text-white max-w-[180px] backdrop-blur-sm">
    <div className="mb-1 font-semibold text-[11px]">Data Nexus</div>
    {hoveredDataset ? (
      <>
        <div className="text-[9px] text-gray-400">Selected Dataset:</div>
        <div className="font-medium text-[10px]" style={{ color: getCategoryColor(hoveredDataset.category) }}>
          {hoveredDataset.name}
        </div>
        <div className="text-[9px] mt-1 flex justify-between">
          <span className="text-gray-400">Impact Score: </span>
          <span className="font-medium">{hoveredDataset.value}%</span>
        </div>
        <div className="text-[9px] mt-1 flex justify-between">
          <span className="text-gray-400">Category: </span>
          <span className="font-medium capitalize">{hoveredDataset.category}</span>
        </div>
      </>
    ) : (
      <div className="text-[9px] text-gray-400">
        Hover over nodes to see details
      </div>
    )}
  </div>
);

// Timestamp and Instructions component
interface TimestampProps {
  date: string;
  time: string;
}

export const TimestampBar: React.FC<TimestampProps> = ({ date, time }) => (
  <div className="absolute top-2 left-2/4 transform -translate-x-1/2 py-1 px-2 bg-[rgba(13,17,23,0.6)] rounded-lg border border-gray-800 flex items-center justify-center gap-3 backdrop-blur-sm">
    <div className="animate-pulse h-1 w-1 rounded-full bg-blue-500"></div>
    <div className="text-[8px] text-gray-300">LIVE DATA • {date} • {time}</div>
    <div className="text-[8px] text-blue-300 ml-2 hidden md:block">Hover over nodes to explore</div>
  </div>
);

// Category Legend component
interface CategoryLegendProps {
  getCategoryColor: (category: string) => string;
}

export const CategoryLegend: React.FC<CategoryLegendProps> = ({ getCategoryColor }) => (
  <div className="absolute top-2 left-2 p-1 bg-[rgba(13,17,23,0.6)] rounded-lg border border-gray-800 flex flex-wrap gap-x-3 gap-y-1 max-w-[180px] backdrop-blur-sm">
    {['financial', 'government', 'risk', 'economic'].map(category => (
      <div key={category} className="flex items-center gap-1">
        <div className="w-2 h-2 rounded-full" style={{ backgroundColor: getCategoryColor(category) }} />
        <span className="text-gray-400 text-[8px] capitalize">{category}</span>
      </div>
    ))}
  </div>
);

// Analysis Indicators component
interface AnalysisIndicatorsProps {
  confidenceLevel: string;
  confidenceClass: string;
  predictedMove: string;
  predictedMoveClass: string;
  keyFactors: string;
}

export const AnalysisIndicators: React.FC<AnalysisIndicatorsProps> = ({
  confidenceLevel,
  confidenceClass,
  predictedMove,
  predictedMoveClass,
  keyFactors,
}) => (
  <div className="absolute -bottom-28 left-2 right-2 grid grid-cols-2 gap-2 bg-[rgba(13,17,23,0.6)] rounded-lg border border-gray-800 p-1.5 backdrop-blur-sm">
    <div className="bg-[rgba(17,24,39,0.6)] rounded p-1 border border-gray-800">
      <div className="text-[8px] text-gray-400">Confidence</div>
      <div className={`font-medium text-xs ${confidenceClass} transition-all duration-500`}>
        {confidenceLevel}
      </div>
    </div>
    <div className="bg-[rgba(17,24,39,0.7)] rounded p-1 border border-gray-800">
      <div className="text-[8px] text-gray-400">Predicted Move</div>
      <div className={`font-medium text-xs ${predictedMoveClass} transition-all duration-500`}>
        {predictedMove}
      </div>
    </div>
    <div className="bg-[rgba(17,24,39,0.7)] rounded p-1 border border-gray-800">
      <div className="text-[8px] text-gray-400">Key Factors</div>
      <div className="font-medium text-gray-300 text-[8px] transition-all duration-500 line-clamp-1">
        {keyFactors}
      </div>
    </div>
    <div className="bg-[rgba(17,24,39,0.7)] rounded p-1 border border-gray-800">
      <div className="text-[8px] text-gray-400">Time Horizon</div>
      <div className="font-medium text-[10px] text-gray-300">7 days</div>
    </div>
  </div>
);
