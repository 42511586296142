// Types for our datasets and connections
export interface Dataset {
  id: string;
  name: string;
  category: 'financial' | 'government' | 'risk' | 'economic';
  x: number;
  y: number;
  radius: number;
  value: number;
  connections: string[];
}

export interface Connection {
  source: string;
  target: string;
  strength: number;
}

export interface PredictionPath {
  main: string;
  upper: string;
  lower: string;
}

export interface DataFlowParticle {
  id: string;
  x: number;
  y: number;
  sourceId: string;
  targetId: string;
  progress: number;
}

export interface DataNexusVisualizationProps {
  className?: string;
}

// Helper functions for the DataNexus visualization
export const getCategoryColor = (category: string): string => {
  switch (category) {
    case 'financial':
      return '#4285F4'; // Refined blue
    case 'government':
      return '#0F9D58'; // Refined green
    case 'risk':
      return '#DB4437'; // Refined red
    case 'economic':
      return '#9C27B0'; // Refined purple
    default:
      return '#757575'; // Refined gray
  }
};

// Get the opacity for a category
export const getCategoryOpacity = (category: string, isHovered: boolean): number => {
  const baseOpacity = {
    financial: 0.45,
    government: 0.45,
    risk: 0.43,
    economic: 0.43,
  }[category] || 0.4;
  
  return isHovered ? baseOpacity + 0.25 : baseOpacity;
};

// Get class for confidence level
export const getConfidenceClass = (confidenceLevel: string): string => {
  return confidenceLevel.startsWith("8") || confidenceLevel.startsWith("9") 
    ? "text-green-400" 
    : confidenceLevel.startsWith("7") 
      ? "text-blue-400" 
      : "text-yellow-500";
};

// Get class for predicted move
export const getPredictedMoveClass = (predictedMove: string): string => {
  return predictedMove.startsWith("+") 
    ? "text-green-400" 
    : "text-red-400";
};

// Dataset variations for rotation
export interface DatasetGroup {
  id: string;
  datasets: Dataset[];
}

// First dataset configuration
const datasetGroup1: Dataset[] = [
  // Financial data nodes (positioned on the right side)
  { id: 'market1', name: 'S&P 500', category: 'financial', x: 190, y: 22, radius: 4, value: 85, connections: [] },
  { id: 'market2', name: 'NASDAQ', category: 'financial', x: 210, y: 42, radius: 4.2, value: 90, connections: [] },
  { id: 'market3', name: 'Russell 2000', category: 'financial', x: 180, y: 68, radius: 3.8, value: 76, connections: [] },
  { id: 'market4', name: 'Treasury Yields', category: 'financial', x: 195, y: 88, radius: 4, value: 65, connections: [] },
  { id: 'market5', name: 'Forex Trends', category: 'financial', x: 215, y: 32, radius: 3.6, value: 68, connections: [] },
  
  // Government data nodes (positioned on the left side)
  { id: 'gov1', name: 'SEC Filings', category: 'government', x: 18, y: 22, radius: 4.5, value: 92, connections: ['market1', 'risk1', 'risk3'] },
  { id: 'gov2', name: 'Government Contracts', category: 'government', x: 25, y: 48, radius: 4.2, value: 87, connections: ['market3', 'econ3', 'alt1'] },
  { id: 'gov3', name: 'Lobbying Data', category: 'government', x: 15, y: 68, radius: 4, value: 78, connections: ['market4', 'alt2'] },
  { id: 'gov4', name: 'Congressional Data', category: 'government', x: 30, y: 82, radius: 4.2, value: 82, connections: ['market2', 'econ1'] },
  { id: 'gov5', name: 'Regulatory Changes', category: 'government', x: 10, y: 38, radius: 3.9, value: 84, connections: ['market5', 'risk4'] },
  
  // Risk indicator nodes
  { id: 'risk1', name: 'Bankruptcy Risk', category: 'risk', x: 55, y: 25, radius: 3.8, value: 68, connections: ['gov1', 'alt1'] },
  { id: 'risk2', name: 'Liquidity Data', category: 'risk', x: 150, y: 32, radius: 4, value: 74, connections: ['market1', 'alt3'] },
  { id: 'risk3', name: 'Turing Risk Index', category: 'risk', x: 100, y: 18, radius: 4.2, value: 79, connections: ['market4', 'risk1'] },
  { id: 'risk4', name: 'Market Volatility', category: 'risk', x: 135, y: 22, radius: 3.7, value: 77, connections: ['gov5', 'market5', 'econ4'] },
  
  // Economic data nodes
  { id: 'econ1', name: 'Core Economic Data', category: 'economic', x: 100, y: 78, radius: 4.4, value: 88, connections: ['market3', 'econ3', 'alt2'] },
  { id: 'econ2', name: 'Asset Rotation', category: 'economic', x: 158, y: 85, radius: 3.8, value: 72, connections: ['market4', 'econ4'] },
  { id: 'econ3', name: 'Government Traffic', category: 'economic', x: 42, y: 75, radius: 3.7, value: 65, connections: ['gov2', 'alt5'] },
  { id: 'econ4', name: 'Institutional Trading', category: 'economic', x: 130, y: 64, radius: 4.1, value: 76, connections: ['market2', 'alt4'] },
  
  // Additional nodes
  { id: 'alt1', name: 'News Sentiment', category: 'economic', x: 75, y: 46, radius: 4, value: 81, connections: ['risk1', 'alt5'] },
  { id: 'alt2', name: 'Pharma Trials', category: 'economic', x: 95, y: 52, radius: 3.8, value: 77, connections: ['gov3', 'econ1'] },
  { id: 'alt3', name: 'Short Selling', category: 'risk', x: 125, y: 45, radius: 3.6, value: 71, connections: ['risk2', 'risk4'] },
  { id: 'alt4', name: 'Wikipedia Views', category: 'economic', x: 175, y: 52, radius: 3.8, value: 68, connections: ['econ4', 'market2'] },
  { id: 'alt5', name: 'Request Data', category: 'government', x: 60, y: 62, radius: 3.9, value: 74, connections: ['econ3', 'alt1'] },
];

// Second dataset configuration with different focus
const datasetGroup2: Dataset[] = [
  // Financial data nodes
  { id: 'market1', name: 'S&P 500', category: 'financial', x: 200, y: 28, radius: 4, value: 85, connections: [] },
  { id: 'market2', name: 'NASDAQ', category: 'financial', x: 195, y: 50, radius: 4.2, value: 90, connections: [] },
  { id: 'market3', name: 'Dow Jones', category: 'financial', x: 185, y: 70, radius: 4, value: 83, connections: [] },
  { id: 'market4', name: 'VIX Index', category: 'financial', x: 205, y: 85, radius: 3.8, value: 78, connections: [] },
  { id: 'market5', name: 'Bond Markets', category: 'financial', x: 215, y: 38, radius: 4.1, value: 81, connections: [] },
  
  // Government data nodes
  { id: 'gov1', name: 'SEC 10K Filings', category: 'government', x: 20, y: 28, radius: 4.5, value: 92, connections: ['market1'] },
  { id: 'gov2', name: 'Regulatory Changes', category: 'government', x: 15, y: 50, radius: 4.2, value: 87, connections: ['market3'] },
  { id: 'gov3', name: 'CFPB Complaints', category: 'government', x: 25, y: 70, radius: 4, value: 78, connections: ['market4'] },
  { id: 'gov4', name: 'Employee Visas', category: 'government', x: 18, y: 85, radius: 4.1, value: 82, connections: ['market2'] },
  { id: 'gov5', name: 'Patents', category: 'government', x: 12, y: 38, radius: 4.3, value: 89, connections: ['market5'] },
  
  // Risk indicator nodes
  { id: 'risk1', name: 'Credit Default Risk', category: 'risk', x: 58, y: 28, radius: 3.8, value: 73, connections: ['gov1'] },
  { id: 'risk2', name: 'Insider Flow', category: 'risk', x: 155, y: 32, radius: 4, value: 81, connections: ['market1'] },
  { id: 'risk3', name: 'Price Breakout', category: 'risk', x: 105, y: 22, radius: 4.2, value: 79, connections: ['market4'] },
  { id: 'risk4', name: 'Bankruptcy Predictions', category: 'risk', x: 125, y: 28, radius: 3.9, value: 75, connections: ['gov5'] },
  
  // Economic data nodes
  { id: 'econ1', name: 'Factor Signals', category: 'economic', x: 105, y: 75, radius: 4.4, value: 88, connections: ['market3'] },
  { id: 'econ2', name: 'Financial Ratios', category: 'economic', x: 155, y: 78, radius: 3.8, value: 72, connections: ['market4'] },
  { id: 'econ3', name: 'Institutional Trading', category: 'economic', x: 50, y: 78, radius: 3.7, value: 65, connections: ['gov3'] },
  { id: 'econ4', name: 'Accounting Data', category: 'economic', x: 135, y: 68, radius: 4.0, value: 79, connections: ['risk3'] },
  
  // Additional nodes
  { id: 'alt1', name: 'AirBnB Data', category: 'economic', x: 78, y: 48, radius: 4, value: 67, connections: ['risk1'] },
  { id: 'alt2', name: 'Box Office Stats', category: 'economic', x: 95, y: 45, radius: 3.8, value: 71, connections: ['gov2'] },
  { id: 'alt3', name: 'Wikipedia Views', category: 'economic', x: 128, y: 50, radius: 3.6, value: 69, connections: ['risk2'] },
  { id: 'alt4', name: 'News Sentiment', category: 'economic', x: 168, y: 52, radius: 3.9, value: 72, connections: ['market2'] },
  { id: 'alt5', name: 'Clinical Trials', category: 'government', x: 38, y: 60, radius: 4.0, value: 76, connections: ['econ2'] },
];

// Third dataset configuration with risk focus
const datasetGroup3: Dataset[] = [
  // Financial data nodes
  { id: 'market1', name: 'Euro Stoxx', category: 'financial', x: 205, y: 28, radius: 4, value: 85, connections: [] },
  { id: 'market2', name: 'Nikkei 225', category: 'financial', x: 190, y: 50, radius: 4.2, value: 90, connections: [] },
  { id: 'market3', name: 'Hang Seng', category: 'financial', x: 200, y: 70, radius: 4, value: 83, connections: [] },
  { id: 'market4', name: 'FTSE 100', category: 'financial', x: 210, y: 85, radius: 3.8, value: 78, connections: [] },
  { id: 'market5', name: 'DAX', category: 'financial', x: 220, y: 38, radius: 3.9, value: 82, connections: [] },
  
  // Government data nodes
  { id: 'gov1', name: 'Patents', category: 'government', x: 15, y: 28, radius: 4.5, value: 92, connections: ['market1'] },
  { id: 'gov2', name: 'Clinical Trials', category: 'government', x: 18, y: 50, radius: 4.2, value: 87, connections: ['market3'] },
  { id: 'gov3', name: 'Earnings Surprise', category: 'government', x: 22, y: 70, radius: 4, value: 78, connections: ['market4'] },
  { id: 'gov4', name: 'Congressional Trades', category: 'government', x: 25, y: 85, radius: 4.1, value: 82, connections: ['market2'] },
  { id: 'gov5', name: 'Lobbying Data', category: 'government', x: 10, y: 38, radius: 4.0, value: 79, connections: ['market5'] },
  
  // Risk indicator nodes
  { id: 'risk1', name: 'Bankruptcy Predictions', category: 'risk', x: 50, y: 30, radius: 3.8, value: 73, connections: ['gov1'] },
  { id: 'risk2', name: 'Risk Indicators', category: 'risk', x: 140, y: 28, radius: 4, value: 81, connections: ['market1'] },
  { id: 'risk3', name: 'Short Selling', category: 'risk', x: 110, y: 20, radius: 4.2, value: 79, connections: ['market4'] },
  { id: 'risk4', name: 'Credit Default', category: 'risk', x: 130, y: 25, radius: 3.8, value: 74, connections: ['gov5'] },
  
  // Economic data nodes
  { id: 'econ1', name: 'Accounting Data', category: 'economic', x: 110, y: 75, radius: 4.4, value: 88, connections: ['market3'] },
  { id: 'econ2', name: 'Liquidity Data', category: 'economic', x: 145, y: 80, radius: 3.8, value: 72, connections: ['market4'] },
  { id: 'econ3', name: 'Government Contracts', category: 'economic', x: 45, y: 78, radius: 3.7, value: 65, connections: ['gov2'] },
  { id: 'econ4', name: 'Asset Rotation', category: 'economic', x: 125, y: 70, radius: 3.9, value: 77, connections: ['market2'] },
  
  // Additional nodes
  { id: 'alt1', name: 'SEC Edgar Search', category: 'government', x: 55, y: 50, radius: 4, value: 67, connections: ['risk1'] },
  { id: 'alt2', name: 'Pharma Trials', category: 'government', x: 80, y: 45, radius: 3.8, value: 71, connections: ['gov3'] },
  { id: 'alt3', name: 'Lobbying Data', category: 'government', x: 100, y: 50, radius: 3.6, value: 69, connections: ['risk3'] },
  { id: 'alt4', name: 'Insider Flow', category: 'risk', x: 175, y: 55, radius: 3.7, value: 70, connections: ['econ4'] },
  { id: 'alt5', name: 'Financial Ratios', category: 'economic', x: 35, y: 60, radius: 3.9, value: 75, connections: ['gov4'] },
];

// Dataset groups for rotation
export const datasetGroups: DatasetGroup[] = [
  { id: 'primary', datasets: datasetGroup1 },
  { id: 'secondary', datasets: datasetGroup2 },
  { id: 'tertiary', datasets: datasetGroup3 },
];

// Get a random dataset group
export const getRandomDatasetGroup = (): DatasetGroup => {
  const randomIndex = Math.floor(Math.random() * datasetGroups.length);
  return datasetGroups[randomIndex];
};

// Helper function to pick a dataset group different from current
export const getNextDatasetGroup = (currentId: string): DatasetGroup => {
  const currentIndex = datasetGroups.findIndex(g => g.id === currentId);
  const nextIndex = (currentIndex + 1) % datasetGroups.length;
  return datasetGroups[nextIndex];
};

// Default datasets
export const getDefaultDatasets = (): Dataset[] => {
  return datasetGroup1;
};

// Default prediction paths - Starting point at x=160, y=10.3 (matching our new historical line endpoint)
export const getDefaultPredictionPaths = (): PredictionPath => {
  return {
    main: "M160,10.3 L170,9.6 L180,8.9 L190,8.5 L200,7.8 L210,7.2 L220,6.8 L230,6.3 L240,5.9",
    upper: "M160,10.3 L170,8.6 L180,7.0 L190,5.5 L200,4.0 L210,2.8 L220,1.6 L230,0.5 L240,-0.5",
    lower: "M160,10.3 L170,11.6 L180,12.9 L190,13.5 L200,14.2 L210,14.8 L220,15.3 L230,15.8 L240,16.2",
  };
};

// Prediction path generators based on dataset category - All adjusted to start at x=160, y=10.3
export const getFinancialPaths = (): PredictionPath => ({
  main: "M160,10.3 L170,9.3 L180,8.3 L190,7.2 L200,6.0 L210,5.2 L220,4.5 L230,4.0 L240,3.5",
  upper: "M160,10.3 L170,8.3 L180,6.3 L190,4.3 L200,2.3 L210,0.8 L220,-0.5 L230,-1.5 L240,-2.5",
  lower: "M160,10.3 L170,11.3 L180,12.0 L190,12.5 L200,12.8 L210,12.4 L220,11.8 L230,11.2 L240,10.5",
});

export const getGovernmentPaths = (): PredictionPath => ({
  main: "M160,10.3 L170,11.2 L180,12.0 L190,12.5 L200,12.8 L210,12.2 L220,11.6 L230,11.0 L240,10.5",
  upper: "M160,10.3 L170,9.5 L180,9.0 L190,8.5 L200,8.0 L210,7.5 L220,7.0 L230,6.5 L240,6.0",
  lower: "M160,10.3 L170,12.8 L180,15.3 L190,17.2 L200,18.5 L210,19.2 L220,19.5 L230,19.0 L240,18.5",
});

export const getRiskPaths = (): PredictionPath => ({
  main: "M160,10.3 L170,13.8 L180,17.3 L190,20.8 L200,24.3 L210,27.8 L220,30.3 L230,32.2 L240,34.0",
  upper: "M160,10.3 L170,12.3 L180,14.3 L190,16.3 L200,18.3 L210,20.3 L220,22.0 L230,23.5 L240,24.5",
  lower: "M160,10.3 L170,15.3 L180,20.3 L190,25.3 L200,30.3 L210,35.3 L220,39.2 L230,42.5 L240,45.0",
});

export const getEconomicPaths = (): PredictionPath => ({
  main: "M160,10.3 L170,9.3 L180,8.3 L190,7.2 L200,6.0 L210,5.0 L220,4.2 L230,3.5 L240,3.0",
  upper: "M160,10.3 L170,8.3 L180,6.3 L190,4.3 L200,2.3 L210,0.3 L220,-1.0 L230,-2.2 L240,-3.0",
  lower: "M160,10.3 L170,10.8 L180,11.3 L190,11.5 L200,11.2 L210,10.8 L220,10.3 L230,9.8 L240,9.5",
});

// Adjust path with a subtle variation for animation
export const adjustPath = (path: string, randomShift: number): string => {
  const parts = path.split(' ');
  const newParts = parts.map(part => {
    // Only adjust the Y coordinates (every other part starting from the third element)
    if (part.includes(',')) {
      const [x, y] = part.split(',');
      const newY = parseFloat(y) + randomShift;
      return `${x},${newY.toFixed(1)}`;
    }
    return part;
  });
  return newParts.join(' ');
};

// Format current time
export const getCurrentTime = (): string => {
  const now = new Date();
  return now.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
};

// Format current date
export const getCurrentDate = (): string => {
  const now = new Date();
  return now.toLocaleDateString();
};

// Stock data interfaces
export interface StockData {
  date: string;
  close: number;
}

export interface StockPrediction {
  historical: StockData[];
  predictions: {
    main: StockData[];
    upper: StockData[];
    lower: StockData[];
  };
  ticker: string;
  lastClose: number;
  percentChange: string;
}

// Function to fetch Moderna stock data from Yahoo Finance API
export const fetchModernaStockData = async (): Promise<StockPrediction | null> => {
  try {
    // Use Yahoo Finance API via a CORS proxy or similar service
    // For demo purposes, we'll use a direct request - in production you'd want to use your backend API
    const response = await fetch('https://query1.finance.yahoo.com/v8/finance/chart/MRNA?interval=1d&range=3mo');
    const data = await response.json();
    
    if (!data || !data.chart || !data.chart.result || !data.chart.result[0]) {
      console.error('Invalid data format from Yahoo Finance API');
      return null;
    }
    
    const result = data.chart.result[0];
    const timestamps = result.timestamp;
    const quotes = result.indicators.quote[0];
    const closePrices = quotes.close;
    
    // Process historical data
    const historical: StockData[] = [];
    for (let i = 0; i < timestamps.length; i++) {
      if (closePrices[i] !== null) {
        historical.push({
          date: new Date(timestamps[i] * 1000).toISOString().split('T')[0],
          close: closePrices[i]
        });
      }
    }
    
    // Only use the most recent 40 data points for the visualization
    const recentData = historical.slice(-40);
    
    // Calculate predictions based on historical volatility
    const lastClose = recentData[recentData.length - 1].close;
    const predictions = generatePredictions(recentData, 9); // Generate 9 days of predictions
    
    // Calculate percent change from previous day
    const prevClose = recentData[recentData.length - 2].close;
    const changePercent = ((lastClose - prevClose) / prevClose) * 100;
    const percentChange = (changePercent >= 0 ? '+' : '') + changePercent.toFixed(1) + '%';
    
    return {
      historical: recentData,
      predictions,
      ticker: 'MRNA',
      lastClose,
      percentChange
    };
  } catch (error) {
    console.error('Error fetching Moderna stock data:', error);
    return null;
  }
};

// Function to generate stock predictions based on historical data
const generatePredictions = (historical: StockData[], days: number) => {
  // Calculate the average daily price movement and volatility
  let totalChange = 0;
  let totalVolatility = 0;
  
  for (let i = 1; i < historical.length; i++) {
    const dailyChange = (historical[i].close - historical[i-1].close) / historical[i-1].close;
    totalChange += dailyChange;
    totalVolatility += Math.abs(dailyChange);
  }
  
  const avgDailyChange = totalChange / (historical.length - 1);
  const volatility = totalVolatility / (historical.length - 1);
  
  // Generate predictions
  const lastDate = new Date(historical[historical.length - 1].date);
  const lastPrice = historical[historical.length - 1].close;
  
  const main: StockData[] = [];
  const upper: StockData[] = [];
  const lower: StockData[] = [];
  
  for (let i = 1; i <= days; i++) {
    const nextDate = new Date(lastDate);
    nextDate.setDate(lastDate.getDate() + i);
    const dateStr = nextDate.toISOString().split('T')[0];
    
    // Main prediction with slight bias towards average daily change
    const mainChange = avgDailyChange * i;
    const mainPrice = lastPrice * (1 + mainChange);
    
    // Upper and lower bounds based on volatility
    // More days into the future = more uncertainty
    const uncertainty = volatility * i * 1.5;
    const upperPrice = lastPrice * (1 + mainChange + uncertainty);
    const lowerPrice = lastPrice * (1 + mainChange - uncertainty);
    
    main.push({ date: dateStr, close: mainPrice });
    upper.push({ date: dateStr, close: upperPrice });
    lower.push({ date: dateStr, close: lowerPrice });
  }
  
  return { main, upper, lower };
};

// Function to convert stock data to an SVG path
export const generatePathFromStockData = (
  data: StockData[], 
  svgWidth: number, 
  svgHeight: number, 
  offsetX: number = 0, 
  offsetY: number = 0
): string => {
  if (data.length === 0) return '';
  
  // Find min and max prices for scaling
  const prices = data.map(d => d.close);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRange = maxPrice - minPrice;
  
  // Calculate space for data points
  const availableWidth = svgWidth - offsetX;
  const pointSpacing = availableWidth / (data.length - 1);
  
  // Generate path
  return data.reduce((path, point, index) => {
    // Normalize price to SVG coordinates
    const x = offsetX + index * pointSpacing;
    // Invert y-axis for SVG (higher price = lower y value)
    const normalizedPrice = (point.close - minPrice) / priceRange;
    const y = svgHeight - (normalizedPrice * svgHeight * 0.6 + offsetY);
    
    return `${path}${index === 0 ? 'M' : 'L'}${x.toFixed(1)},${y.toFixed(1)} `;
  }, '');
};

// Convert stock data to prediction paths for the visualization
export const convertStockDataToPredictionPaths = (
  stockData: StockPrediction | null,
  fallbackPaths: PredictionPath
): PredictionPath => {
  if (!stockData) return fallbackPaths;
  
  try {
    // Set constants for the SVG dimensions
    const svgWidth = 240;
    const svgHeight = 40; // This matches the PredictionGraph transform
    
    // Generate historical path first
    const historicalPath = generatePathFromStockData(stockData.historical, svgWidth, svgHeight, 0, 0);
    
    // Find the last point of the historical data for connecting the predictions
    const lastHistoricalX = stockData.historical.length > 0 
      ? (svgWidth * (stockData.historical.length - 1)) / (stockData.historical.length - 1)
      : 160; // Fallback to 160 if no historical data
    
    const lastPrice = stockData.historical.length > 0 
      ? stockData.historical[stockData.historical.length - 1].close 
      : 0;
    
    // Get the y-coordinate of the last point from the historical path
    // This is complex, so we'll use the last known price to approximate it
    const lastHistoricalPoint = historicalPath.split(' ').filter(Boolean).pop();
    const lastHistoricalY = lastHistoricalPoint ? parseFloat(lastHistoricalPoint.split(',')[1]) : 10.3;
    
    // Generate prediction paths
    const mainPath = generatePathFromStockData(
      stockData.predictions.main, 
      svgWidth - lastHistoricalX, 
      svgHeight, 
      lastHistoricalX, 
      0
    );
    
    const upperPath = generatePathFromStockData(
      stockData.predictions.upper, 
      svgWidth - lastHistoricalX, 
      svgHeight, 
      lastHistoricalX, 
      0
    );
    
    const lowerPath = generatePathFromStockData(
      stockData.predictions.lower, 
      svgWidth - lastHistoricalX, 
      svgHeight, 
      lastHistoricalX, 
      0
    );
    
    // Ensure paths start at the same point as where historical data ends
    const fixedMainPath = `M${lastHistoricalX},${lastHistoricalY} ${mainPath.substring(mainPath.indexOf(' ') + 1)}`;
    const fixedUpperPath = `M${lastHistoricalX},${lastHistoricalY} ${upperPath.substring(upperPath.indexOf(' ') + 1)}`;
    const fixedLowerPath = `M${lastHistoricalX},${lastHistoricalY} ${lowerPath.substring(lowerPath.indexOf(' ') + 1)}`;
    
    return {
      main: fixedMainPath,
      upper: fixedUpperPath,
      lower: fixedLowerPath
    };
  } catch (error) {
    console.error('Error converting stock data to paths:', error);
    return fallbackPaths;
  }
};
